import { Component, inject, input } from '@angular/core';
import { CarrierDto, ClaimListDto, ClaimsClient, ClaimStatusEnum, ColumnTypeEnum, FilterDto, FilterItemDto, FiltersClient, FilterTypeEnum, ListTypeEnum, PagedRequestDto } from '../../core/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { UserResolverService } from '../../core/services/user-resolver.service';
import { SpinnerService } from '../../core/spinner/spinner.svc';
import { Observable, of, Subject } from 'rxjs';
import { SnackBarService } from '../../core/services/snack-bar.service';
import { GridBaseComponent } from '../../shared/base-components/material-grid-base/material-grid-api-base.component';
import { RequestService } from '../../core/services/request.service';
import { UserSettingService } from '../../core/services/user-setting.service';
import { Permissions } from '../../shared/common/permissions';
import { MaterialGridEmptyTableComponent } from '../../shared/material-grid-empty-table/material-grid-empty-table.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { GridColumnDisplayComponent } from '../../shared/grid-column-display/grid-column-display.component';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { DateFilterSelectorComponent } from '../../shared/widgets/date-filter-selector/date-filter-selector.component';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { ServiceTypesService } from 'app/core/services/service-types.service';
import { CustomerSearchComponent } from '../../shared/widgets/customer-search/customer-search.component';

@Component({
    selector: 'sn-claims-list',
    templateUrl: './claims.component.html',
    styleUrls: ['./claims.component.scss'],
    imports: [MatFormFieldModule, MatInputModule, FormsModule, MatIconModule, DateFilterSelectorComponent, MatButtonModule, MatMenuModule, MatTableModule, MatSortModule, RouterLink, GridColumnDisplayComponent, MatPaginatorModule, MaterialGridEmptyTableComponent, CustomerSearchComponent]
})
export class ClaimsComponent extends GridBaseComponent<ClaimListDto> {
  protected _matDialog: MatDialog;
  protected _route = inject(ActivatedRoute);
  private _router = inject(Router);
  protected _userResolverService: UserResolverService;
  protected _spinnerService: SpinnerService;
  protected _snackBarService: SnackBarService;
  protected _requestService: RequestService;
  protected _filtersClient: FiltersClient;
  protected _userSettingService: UserSettingService;
  private _claimsClient = inject(ClaimsClient);
  private _serviceTypesService = inject(ServiceTypesService);

  readonly defaultCustomerId = input<number | undefined>(undefined);

  isASM: boolean = false;
  force = new Subject<any>();
  force$ = this.force.asObservable();
  paramMap: { [name: string]: string } = {};
  carrierId: number | undefined;
  claimStatuses = ClaimStatusEnum;
  permissions = Permissions;

  constructor() {
    const _matDialog = inject(MatDialog);
    const _userResolverService = inject(UserResolverService);
    const _spinnerService = inject(SpinnerService);
    const _snackBarService = inject(SnackBarService);
    const _requestService = inject(RequestService);
    const _filtersClient = inject(FiltersClient);
    const _userSettingService = inject(UserSettingService);

    super(_matDialog, _userResolverService, _spinnerService, _snackBarService, _filtersClient, _userSettingService, _requestService);
    this._matDialog = _matDialog;
    this._userResolverService = _userResolverService;
    this._spinnerService = _spinnerService;
    this._snackBarService = _snackBarService;
    this._requestService = _requestService;
    this._filtersClient = _filtersClient;
    this._userSettingService = _userSettingService;


    this.listTypes = ListTypeEnum.Claims;
    this._userResolverService.userInfo.subscribe(x => this.isASMUser = x.IsASM);
  }

  getFilters(): Observable<FilterDto[]> {
    return of([]);
  }

  getGridColumns() {
    return this._claimsClient.claims_GetGridOptions();
  }

  getDefaultCustomerFilter(): FilterItemDto {
    return FilterItemDto.fromJS({
      column: 'customerId',
      value1: this.defaultCustomerId().toString(),
      columnType: ColumnTypeEnum.Text,
      filterType: FilterTypeEnum.Equals
    })
  }

  getData() {
    let request = this.getPagedRequest();

    let keys = this._route.snapshot.paramMap.keys;

    const defaultCustomerId = this.defaultCustomerId();
    if(keys.length > 0 && !defaultCustomerId) {
      keys.forEach(param => {
        if(param == "title")  {
          return;
        }
        let colName = this.paramMap[param] || param;
        let value = this._route.snapshot.paramMap.get(param);
        let filterItem = FilterItemDto.fromJS({
          column: colName,
          value1: value,
          filterType: FilterTypeEnum.Equals
        });

        switch (param) {
          case "carrierId": this.filterTitle = `${this._route.snapshot.paramMap.get("title")}`;
            filterItem.columnType = ColumnTypeEnum.Carrier;
            break;
          default: break;

        }
        request.filterItems?.push(filterItem);
      });
    }

    if(this.carrierId) {
      request.filterItems?.push(FilterItemDto.fromJS({
        column: 'carrierId',
        value1: ""+this.carrierId,
        columnType: ColumnTypeEnum.Carrier,
        filterType: FilterTypeEnum.Equals
      }));
    }
    if(this.dateFilter?.customerId && !defaultCustomerId) {
      let customerFilter = FilterItemDto.fromJS({
        column: "customerId",
        value1: this.dateFilter.customerId.toString(),
        filterType: FilterTypeEnum.Equals,
        columnType: ColumnTypeEnum.Text
      });

      request.filterItems?.push(customerFilter);
    }
    var otherFilters = this.getFilterFromQueryParam();
    request.filterItems?.push(...otherFilters);
    if(defaultCustomerId){
      request.filterItems = request.filterItems.filter(x => x.column != "customerId");
      request.filterItems.push(this.getDefaultCustomerFilter());
    }

    return this._claimsClient.claims_GetDynamicPagedList(request, this.searchTerm);
  }

  getExcelExportData() {
    return this._claimsClient.claims_ExportDynamicList(this.getPagedRequest(), this.searchTerm, Intl.DateTimeFormat().resolvedOptions().timeZone);
  }

  getPagedRequest(): PagedRequestDto {
      var request = super.getPagedRequest();
      var dateFilter = this.getDateFilter()
      if(this.dateFilter && dateFilter) {
        request.filterItems?.push();
      }

      return request;
  }

  trackClaim(index: number, item: ClaimListDto) {
    return item.id;
  }

  setCarrier(selectedCarrier: CarrierDto[]) {
    if(selectedCarrier && selectedCarrier.length == 1) {
      this.carrierId = selectedCarrier[0].id;
    }
    else {
      this.carrierId = undefined
    }
    this.refreshData();
  }

  deleteDrillDown() {
    this.filterTitle = "";
    this._router.navigate(['claims'])
  }

  getFilterFromQueryParam() {
    var filters: FilterItemDto[] = [];
    const keys = this._route.snapshot.queryParamMap.keys;
    
    for (const element of keys) {
      switch (element) {
        case 'serviceTypes':
          const selectedServiceTypesIndex = this._route.snapshot.queryParamMap.get(element);
          if (!this._serviceTypesService.isAllServiceTypeSelected(selectedServiceTypesIndex)) {
            let filterItem = FilterItemDto.fromJS({
              column: "serviceTypeID",
              value1: selectedServiceTypesIndex,
              filterType: FilterTypeEnum.Contains,
              columnType: ColumnTypeEnum.SelectNumeric
            });
            filters.push(filterItem)

            const serviceTypeIds: number[] = selectedServiceTypesIndex.split(",").map(Number);

            for (const serviceTypeId of serviceTypeIds) {
              this.filterTitle = this.filterTitle?.length ? `${this.filterTitle}, ${this._serviceTypesService.getEnumValueByIndex(serviceTypeId)}` 
              : `${this._serviceTypesService.getEnumValueByIndex(serviceTypeId)}`;
            }

          }
          break;
        default:
          break;
      }
    };

    return filters;
  }
}
